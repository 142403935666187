import React from "react";
import { Select } from "../../../helpers";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { getDepartmentOptions } from "../../../../selectors/dropdowns";

const DepartmentSelect = ({ values, onChange, placeholder = "All" }) => {
  const departmentOptions = createOptions(
    useSelector(getDepartmentOptions),
    "key",
    "value",
    { addAll: true },
  );

  return (
    <Select
      value={values}
      onChange={(e) => onChange(e?.value || null)}
      isClearable={values !== ""}
      options={departmentOptions}
      placeholder={placeholder}
    />
  );
};

export default DepartmentSelect;
